import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d9e0fa1 = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _5694624f = () => interopDefault(import('../pages/candidate/_id/index.vue' /* webpackChunkName: "pages/candidate/_id/index" */))
const _367e5629 = () => interopDefault(import('../pages/candidate/_id/academy.vue' /* webpackChunkName: "pages/candidate/_id/academy" */))
const _a42dffbc = () => interopDefault(import('../pages/candidate/_id/activity.vue' /* webpackChunkName: "pages/candidate/_id/activity" */))
const _d6acd2ec = () => interopDefault(import('../pages/candidate/_id/autoSearch.vue' /* webpackChunkName: "pages/candidate/_id/autoSearch" */))
const _2647e225 = () => interopDefault(import('../pages/candidate/_id/general.vue' /* webpackChunkName: "pages/candidate/_id/general" */))
const _cff0be04 = () => interopDefault(import('../pages/candidate/_id/interview.vue' /* webpackChunkName: "pages/candidate/_id/interview" */))
const _4a875ce7 = () => interopDefault(import('../pages/candidate/_id/matchesTalks.vue' /* webpackChunkName: "pages/candidate/_id/matchesTalks" */))
const _55e54573 = () => interopDefault(import('../pages/candidate/_id/referrals.vue' /* webpackChunkName: "pages/candidate/_id/referrals" */))
const _5c0799ca = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _54aefe2c = () => interopDefault(import('../pages/company/_id/contacts.vue' /* webpackChunkName: "pages/company/_id/contacts" */))
const _3c0ac2c2 = () => interopDefault(import('../pages/company/_id/general.vue' /* webpackChunkName: "pages/company/_id/general" */))
const _369e492f = () => interopDefault(import('../pages/company/_id/jobs.vue' /* webpackChunkName: "pages/company/_id/jobs" */))
const _7f52d331 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _f409b54a = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _dc49d4e0 = () => interopDefault(import('../pages/job/_id/autoSearch.vue' /* webpackChunkName: "pages/job/_id/autoSearch" */))
const _05e030df = () => interopDefault(import('../pages/job/_id/general.vue' /* webpackChunkName: "pages/job/_id/general" */))
const _701dbe4c = () => interopDefault(import('../pages/job/_id/missionSheet.vue' /* webpackChunkName: "pages/job/_id/missionSheet" */))
const _596126fb = () => interopDefault(import('../pages/job/_id/permissions.vue' /* webpackChunkName: "pages/job/_id/permissions" */))
const _0016caf7 = () => interopDefault(import('../pages/job/_id/pitch.vue' /* webpackChunkName: "pages/job/_id/pitch" */))
const _2a979926 = () => interopDefault(import('../pages/job/_id/ratings.vue' /* webpackChunkName: "pages/job/_id/ratings" */))
const _ee270cf2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4483154c = () => interopDefault(import('../pages/ai_tools/aiTools.vue' /* webpackChunkName: "pages/ai_tools/aiTools" */))
const _e21fa1c8 = () => interopDefault(import('../pages/ai_tools/kiaditca.js' /* webpackChunkName: "pages/ai_tools/kiaditca" */))
const _08cce036 = () => interopDefault(import('../pages/listing/candidate-admitted.vue' /* webpackChunkName: "pages/listing/candidate-admitted" */))
const _6dd90b2c = () => interopDefault(import('../pages/listing/candidate-applications.vue' /* webpackChunkName: "pages/listing/candidate-applications" */))
const _419f0b84 = () => interopDefault(import('../pages/listing/candidate-applications/interested.vue' /* webpackChunkName: "pages/listing/candidate-applications/interested" */))
const _6b1257fb = () => interopDefault(import('../pages/listing/candidate-applications/new.vue' /* webpackChunkName: "pages/listing/candidate-applications/new" */))
const _76c9d112 = () => interopDefault(import('../pages/listing/candidate-applications/opport-check.vue' /* webpackChunkName: "pages/listing/candidate-applications/opport-check" */))
const _0b39c0aa = () => interopDefault(import('../pages/listing/candidate-applications/refused.vue' /* webpackChunkName: "pages/listing/candidate-applications/refused" */))
const _432149f6 = () => interopDefault(import('../pages/listing/candidate-db.vue' /* webpackChunkName: "pages/listing/candidate-db" */))
const _8ff5cb82 = () => interopDefault(import('../pages/listing/candidate-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals" */))
const _1ad69aea = () => interopDefault(import('../pages/listing/candidate-referrals/no-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals/no-referrals" */))
const _612ce45e = () => interopDefault(import('../pages/listing/candidate-referrals/ref-created.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-created" */))
const _7e8800e6 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-pending.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-pending" */))
const _19110a64 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-requested.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-requested" */))
const _431000a2 = () => interopDefault(import('../pages/listing/candidate-search.vue' /* webpackChunkName: "pages/listing/candidate-search" */))
const _ba0aef5a = () => interopDefault(import('../pages/listing/jobs.vue' /* webpackChunkName: "pages/listing/jobs" */))
const _3118bfc6 = () => interopDefault(import('../pages/listing/matches.vue' /* webpackChunkName: "pages/listing/matches" */))
const _1540d075 = () => interopDefault(import('../pages/listing/matches/candidate-check.vue' /* webpackChunkName: "pages/listing/matches/candidate-check" */))
const _12490e79 = () => interopDefault(import('../pages/listing/matches/check-before-admission.vue' /* webpackChunkName: "pages/listing/matches/check-before-admission" */))
const _5ab7b7df = () => interopDefault(import('../pages/listing/matches/client-check.vue' /* webpackChunkName: "pages/listing/matches/client-check" */))
const _0afef1e7 = () => interopDefault(import('../pages/listing/matches/hired.vue' /* webpackChunkName: "pages/listing/matches/hired" */))
const _5806ef14 = () => interopDefault(import('../pages/listing/matches/interested-before-admission.vue' /* webpackChunkName: "pages/listing/matches/interested-before-admission" */))
const _371bcfde = () => interopDefault(import('../pages/listing/matches/internal-check.vue' /* webpackChunkName: "pages/listing/matches/internal-check" */))
const _64bc6d92 = () => interopDefault(import('../pages/listing/matches/interview-steps.vue' /* webpackChunkName: "pages/listing/matches/interview-steps" */))
const _6734f653 = () => interopDefault(import('../pages/listing/matches/offer.vue' /* webpackChunkName: "pages/listing/matches/offer" */))
const _47eb4907 = () => interopDefault(import('../pages/listing/matches/refused.vue' /* webpackChunkName: "pages/listing/matches/refused" */))
const _81765320 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _0d9e0fa1,
    name: "candidate",
    children: [{
      path: ":id",
      component: _5694624f,
      name: "candidate-id"
    }, {
      path: ":id?/academy",
      component: _367e5629,
      name: "candidate-id-academy"
    }, {
      path: ":id?/activity",
      component: _a42dffbc,
      name: "candidate-id-activity"
    }, {
      path: ":id?/autoSearch",
      component: _d6acd2ec,
      name: "candidate-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _2647e225,
      name: "candidate-id-general"
    }, {
      path: ":id?/interview",
      component: _cff0be04,
      name: "candidate-id-interview"
    }, {
      path: ":id?/matchesTalks",
      component: _4a875ce7,
      name: "candidate-id-matchesTalks"
    }, {
      path: ":id?/referrals",
      component: _55e54573,
      name: "candidate-id-referrals"
    }]
  }, {
    path: "/company",
    component: _5c0799ca,
    name: "company",
    children: [{
      path: ":id?/contacts",
      component: _54aefe2c,
      name: "company-id-contacts"
    }, {
      path: ":id?/general",
      component: _3c0ac2c2,
      name: "company-id-general"
    }, {
      path: ":id?/jobs",
      component: _369e492f,
      name: "company-id-jobs"
    }]
  }, {
    path: "/home",
    component: _7f52d331,
    name: "home"
  }, {
    path: "/job",
    component: _f409b54a,
    name: "job",
    children: [{
      path: ":id?/autoSearch",
      component: _dc49d4e0,
      name: "job-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _05e030df,
      name: "job-id-general"
    }, {
      path: ":id?/missionSheet",
      component: _701dbe4c,
      name: "job-id-missionSheet"
    }, {
      path: ":id?/permissions",
      component: _596126fb,
      name: "job-id-permissions"
    }, {
      path: ":id?/pitch",
      component: _0016caf7,
      name: "job-id-pitch"
    }, {
      path: ":id?/ratings",
      component: _2a979926,
      name: "job-id-ratings"
    }]
  }, {
    path: "/login",
    component: _ee270cf2,
    name: "login"
  }, {
    path: "/ai_tools/aiTools",
    component: _4483154c,
    name: "ai_tools-aiTools"
  }, {
    path: "/ai_tools/kiaditca",
    component: _e21fa1c8,
    name: "ai_tools-kiaditca"
  }, {
    path: "/listing/candidate-admitted",
    component: _08cce036,
    name: "listing-candidate-admitted"
  }, {
    path: "/listing/candidate-applications",
    component: _6dd90b2c,
    name: "listing-candidate-applications",
    children: [{
      path: "interested",
      component: _419f0b84,
      name: "listing-candidate-applications-interested"
    }, {
      path: "new",
      component: _6b1257fb,
      name: "listing-candidate-applications-new"
    }, {
      path: "opport-check",
      component: _76c9d112,
      name: "listing-candidate-applications-opport-check"
    }, {
      path: "refused",
      component: _0b39c0aa,
      name: "listing-candidate-applications-refused"
    }]
  }, {
    path: "/listing/candidate-db",
    component: _432149f6,
    name: "listing-candidate-db"
  }, {
    path: "/listing/candidate-referrals",
    component: _8ff5cb82,
    name: "listing-candidate-referrals",
    children: [{
      path: "no-referrals",
      component: _1ad69aea,
      name: "listing-candidate-referrals-no-referrals"
    }, {
      path: "ref-created",
      component: _612ce45e,
      name: "listing-candidate-referrals-ref-created"
    }, {
      path: "ref-pending",
      component: _7e8800e6,
      name: "listing-candidate-referrals-ref-pending"
    }, {
      path: "ref-requested",
      component: _19110a64,
      name: "listing-candidate-referrals-ref-requested"
    }]
  }, {
    path: "/listing/candidate-search",
    component: _431000a2,
    name: "listing-candidate-search"
  }, {
    path: "/listing/jobs",
    component: _ba0aef5a,
    name: "listing-jobs"
  }, {
    path: "/listing/matches",
    component: _3118bfc6,
    name: "listing-matches",
    children: [{
      path: "candidate-check",
      component: _1540d075,
      name: "listing-matches-candidate-check"
    }, {
      path: "check-before-admission",
      component: _12490e79,
      name: "listing-matches-check-before-admission"
    }, {
      path: "client-check",
      component: _5ab7b7df,
      name: "listing-matches-client-check"
    }, {
      path: "hired",
      component: _0afef1e7,
      name: "listing-matches-hired"
    }, {
      path: "interested-before-admission",
      component: _5806ef14,
      name: "listing-matches-interested-before-admission"
    }, {
      path: "internal-check",
      component: _371bcfde,
      name: "listing-matches-internal-check"
    }, {
      path: "interview-steps",
      component: _64bc6d92,
      name: "listing-matches-interview-steps"
    }, {
      path: "offer",
      component: _6734f653,
      name: "listing-matches-offer"
    }, {
      path: "refused",
      component: _47eb4907,
      name: "listing-matches-refused"
    }]
  }, {
    path: "/",
    component: _81765320,
    name: "index"
  }, {
    path: "/company/:id/",
    redirect: "/company/:id/general",
    name: "companyId"
  }, {
    path: "/job/:id/",
    redirect: "/job/:id/general",
    name: "jobId"
  }, {
    path: "/listing/matches/",
    redirect: "/listing/matches/internal-check",
    name: "listingMatches"
  }, {
    path: "/listing/application/",
    redirect: "/listing/candidate-applications/new",
    name: "candidateListing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
